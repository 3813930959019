@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "blazing sun outline";
  src: local("BLAZING SUN"),
    url(assets/fonts/BLAZINGSUN-Outline.otf) format("opentype");
}

@font-face {
  font-family: "blazing sun";
  src: local("BLAZING SUN Regular"),
    url(assets/fonts/BLAZINGSUN-Regular.otf) format("opentype");
}

@font-face {
  font-family: "brandon text";
  src: local("Brandon Text"),
    url(assets/fonts/BrandonText-Regular.otf) format("opentype");
}

body {
  font-family: "brandon text";
}

html {
  background-image: linear-gradient(white, white);
}
body {
  background: url(assets/images/desert-pattern-mobile.svg);
  background-size: contain;
  background-repeat: repeat;
}

.font-bs-outline {
  font-family: "blazing sun outline";
}

.font-bs {
  font-family: "blazing sun";
}

.text-cactus {
  color: #8EB35A;
}

.bg-lime {
  background: #CDF593;
}

.bg-pink {
  background: #FFDDDF;
}

.bg-orange {
  background: #F07A46;
}

.bg-blue {
  background: #93C7FE;
}

/*.prick-screen {
  height: calc(100vh - 8rem);
}*/

.background {
  background-image: url(assets/images/desert4.jpg);
  background-size: cover;
  background-position: 60% center;
  background-repeat: no-repeat;
  min-height: 435px;
}

.can {
  pointer-events: none;
}

.first-row {
  max-height: 160px;
}

.first-row .child:nth-of-type(1) {
  width: calc(45% - .5rem);
}

.first-row .child:nth-of-type(2) {
  width: calc(55%);
}

.main-box {
  position: relative;
  /*min-height: calc(443px + 9rem);*/
  box-shadow: -8px 8px black, -7px 7px black, -6px 6px black, -5px 5px black, -4px 4px black, -3px 3px black, -2px 2px black, -1px 1px black;
}

.w-full-full {
  width: 100%;
}

.btn {
  margin: 6px 0px 10px;
  padding: 4px 8px;
  border-width: 3px;
  box-shadow: -4px 4px black, -3px 3px black, -2px 2px black, -1px 1px black;
}

.claim-btn {
  margin-left: 4px;
  margin-right: 4px;
}

.screen {
  background: white /*#F4FCFF*/;
  width: 100%;
  height: 20px;
  border-bottom: 4px solid black;
  position: absolute;
  top: 0;
  left: 0;
}

.question-mark {
  cursor: pointer;
}

.h-full-sf {
  height: calc(100% - 0.5rem);
}
.shake:hover {
  -webkit-animation: headShake 1s;
  animation: headShake 1s;
}

.gif {
  margin: auto;
  background-image: url(assets/images/prick-e.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  -webkit-animation: giffy 5s infinite linear;
  animation: giffy 5s infinite linear;
}

.gif-holder {
  background-image: url(assets/images/prick-e.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@keyframes giffy {
  19%  { background-image: url('assets/images/prick-e.png'); }
  20%  { background-image: url('assets/images/prick-a.png'); }
  39%  { background-image: url('assets/images/prick-a.png'); }
  40%  { background-image: url('assets/images/prick-b.png'); }
  59%  { background-image: url('assets/images/prick-b.png'); }
  60%  { background-image: url('assets/images/prick-c.png'); }
  79%  { background-image: url('assets/images/prick-c.png'); }
  80%  { background-image: url('assets/images/prick-d.png');}
  99%  { background-image: url('assets/images/prick-d.png');}
  100%  { background-image: url('assets/images/prick-e.png'); }
}

/*------ animation for watering -----*/
/*------ animation for watering -----*/

:root {
  --d: 2;
  --a: 2;
  --x: 2;
  --y: 2;
}

.raindrop {
  animation-delay: calc(var(--d) * 1s);
  animation-duration: calc(var(--a) * 1s);
  animation-iteration-count: 7;
  animation-name: drop;
  animation-timing-function: linear;
  height: 10px;
  left: calc(var(--x) * 1%);
  position: absolute;
  top: calc((var(--y) + 5) * -1px);
}

.lex {
  min-width: 60px;
}

@keyframes drop {
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(60px);
  }
}

/*media queries */

@media screen and (min-width: 1700px) {
  .first-row {
    max-height: 260px;
  }
}

@media screen and (min-width: 1200px) {
  .w-can {
    width: 110px;
  }
}

@media screen and (min-width: 960px) {
  .prick-screen {
    height: calc(100vh - 8rem);
    min-height: calc(435px + 8rem);
  }
  .controls {
    min-height: 435px;
  }
  .h-full-sf {
    height: calc(100% - 1rem);
  }
  .background {
    background-position: center;
  }
  .w-full-full {
    width: calc(100% + 0.75rem);
  }
  body {
    background: url(assets/images/desert-pattern.svg);
    background-repeat: repeat;
  }
  .lex {

  }

}
